
import React from "react";
import { GetSupport } from "../Help/Help";

interface ErrorBoundaryState {
  error: Error | undefined;
  showError: boolean;
}

export class ErrorBoundary extends React.Component<React.PropsWithChildren<{}>, ErrorBoundaryState> {
  constructor(props: React.PropsWithChildren<{}>) {
    super(props);
    this.state = { error: undefined, showError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Caught an fatal error: ", { error, errorInfo });
  }

  render() {
    if (this.state.error) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "left",
            height: "100vh",
            width: "90vw",
            margin: "0 auto",
          }}
        >
          <div style={{ height: "5%", display: 'flex' }}>
            <img style={{ height: "100%", width: "auto", objectFit: "contain" }} src="logo.webp" />
          </div>
          <h1>Desculpe, algo inesperado aconteceu.</h1>
          <h2>
            Entre em contato pelo{" "}
            <a href={GetSupport()} target="_blank">
              nosso WhatsApp
            </a>{" "}
            com as informações de erro abaixo.
          </h2>
          <h3>Erro: {this.state.error.message}</h3>

          <button
            type="button"
            style={{
              cursor: "pointer",
              padding: "18px",
              width: "100%",
              border: "none",
              textAlign: "left",
              outline: "none",
              fontSize: "15px",
            }}
            onClick={() =>
              this.setState((state) => ({ showError: !state.showError }))
            }
          >
            Mostrar detalhes do erro
          </button>
          <div
            style={{
              display: this.state.showError ? "block" : "none",
              overflow: "visible",
              height: "0px",
              backgroundColor: "#f1f1f1",
            }}
          >
            <div
              style={{
                backgroundColor: "#f1f1f1",
                padding: "20px 18px",
              }}
            >
              <hr />
              <p>{this.state.error.stack}</p>
            </div>
          </div>
        </div>
      );
    }

    return <>{this.props.children}</>;
  }
}