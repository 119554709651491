import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import SettingsComponent from '../../components/Settings/Settings';
import { InsertEmoticonOutlined, SettingsSuggestOutlined, HelpOutlineOutlined, SavingsOutlined } from '@mui/icons-material';
import Clients from '../../components/Clients/Clients';
import Payments from '../../components/Payments/Payments';
import { Help } from '../../components/Help/Help';
import { Navigator, NavigatorController } from '../../components/Navigator/Navigator';
import { UseChange, UseMount, UseState, UseStateArray } from '../../utils/UseChange';
import { AuthHolder, getUI, getUser, signOut } from '../../utils/Auth';
import { LoadingBackdrop, LoadingController } from '../../components/LoadingBackdrop/LoadingBackdrop';
import { CircularProgress, Stack, Typography } from '@mui/material';
import firebase from 'firebase/compat/app';
import CompanyAvatar from '../../components/CompanyAvatar/CompanyAvatar';
import { GetSessionData, SessionData } from '../../api/SessionData';
import { SignUp } from '../../components/SignUp/SignUp';
import { AUTH_BYPASS } from '../../constants/Configurations';

export default function Home() {
  const [currentTab, setCurrentTab] = React.useState(0);
  const sessionData = UseState<SessionData>({} as SessionData, (data: SessionData) => {
    data.payments?.sort((p1, p2) => (p2.year - p1.year || p2.month - p1.month));
    data.clients?.sort((c1, c2) => c1.name.localeCompare(c2.name));
    return data;
  })
  const navigator = React.useRef(new NavigatorController())
  const signed = UseState<boolean | undefined>(undefined);
  const authHolder = UseState<AuthHolder | undefined>(undefined);
  const loading = React.useRef(new LoadingController());

  const refreshSession = () => {
    loading.current.show()
    sessionData.set({} as SessionData)

    GetSessionData(authHolder.value!).then(data => {
      console.log(data);
      sessionData.set(data);
      loading.current.success();
    }).catch(err => {
      console.error(err);
      loading.current.fail();
    })
  }

  UseChange(() => {
    if (signed.value === true) {
      GetSessionData(authHolder.value!).then(data => {
        console.log(data);
        sessionData.set(data);
      }).catch(console.error)
    }
    if (signed.value === false) {
      getUI().start('#firebaseui-auth-container', {
        signInOptions: [
          {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            customParameters: {
              // Force user choosing account, even if there is only one
              // prompt: 'select_account',
            },
          }
        ],
        callbacks: {
          signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            console.log(`signInSuccessWithAuthResult`);
            return false;
          },
          signInFailure: function (err) {
            console.log(`signInFailure ${err}`);
          },
          uiShown: function () {
            console.log('uiShown');

            // Gambi for translating components
            for (var i in document.getElementsByClassName('firebaseui-idp-text-long')) {
              if (typeof document.getElementsByClassName('firebaseui-idp-text-long')[i].textContent != "undefined") {

                var element = document.getElementsByClassName('firebaseui-idp-text-long')[i];

                if (element != undefined && element.textContent != undefined) {
                  element.textContent = element.textContent.replace("Sign in with Google", "Acessar usando Google");
                }
              }
            }

            loading.current.dismiss();
          }
        },
        signInFlow: 'popup',
        signInSuccessUrl: '/painel',
        // tosUrl: 'https://google.com/',
        // privacyPolicyUrl: 'https://google.com/',
      });
    }
  }, [signed.value])

  UseMount(() => {
    if (AUTH_BYPASS != null) {
      authHolder.set(new AuthHolder(AUTH_BYPASS));
      signed.set(true);
      return;
    }
    if (window.location.pathname === '/painel/signout') {
      signOut().then(() => {
        window.location.href = '/painel';
      });
      return;
    }
    getUser((authUser) => {
      authUser.getIdToken().then(idToken => {
        authHolder.set(new AuthHolder(authUser))
        signed.set(true);
      }).catch(console.error)
    },
      () => {
        signed.set(false);
      })
  });

  const changeTab = (newTab: number) => {
    navigator.current.clear();
    setCurrentTab(newTab);
    window.scrollTo(0, 0);
  };

  const tabs = [
    {
      label: 'Pagamentos',
      icon: <SavingsOutlined />,
      content: <Payments authHolder={authHolder.value!} sessionData={sessionData} navigator={navigator.current} />,
    },
    {
      label: 'Clientes',
      icon: <InsertEmoticonOutlined />,
      content: <Clients authHolder={authHolder.value!} sessionData={sessionData} navigator={navigator.current} />,
    },
    {
      label: 'Configurações',
      icon: <SettingsSuggestOutlined />,
      content: <SettingsComponent navigator={navigator.current} sessionData={sessionData} />,
    },
    {
      label: 'Fale Conosco',
      icon: <HelpOutlineOutlined />,
      content: <Help />
    },
  ]

  return (
    < Box sx={{ width: '100vw' }} >
      <LoadingBackdrop controller={loading.current} />
      {signed.value === false &&
        // Sign in screen
        <Box height='100%' width='100%' display='flex' alignContent='center' justifyContent='center'>
          <Stack height='100%' direction='column' margin={6} display='flex' alignItems='center'>
            <CompanyAvatar size={100} />
            <Typography variant='h4' align='center'>Bem vindo!</Typography>
            {signed.value === false
              ?
              <Box>
                <Typography variant='h6' align='left' paddingTop={3}>Acesse nosso sistema utilizando sua conta google:</Typography>
                <Box paddingTop={3} id="firebaseui-auth-container" />
              </Box>
              :
              <CircularProgress sx={{ marginTop: 3 }} />
            }
          </Stack >
        </Box >
      }
      {signed.value === true && sessionData.value.userCreated === true &&
        // Home screen
        <Box>
          <Box sx={{ pb: 15 }}>
            <Navigator controller={navigator.current} >
              {tabs[currentTab].content}
            </Navigator>
          </Box>
          <Box sx={{ color: 'main', position: 'fixed', bottom: 0, left: 0, right: 0 }}>
            <BottomNavigation
              showLabels
              value={currentTab}
              onChange={(e, v) => changeTab(v)}
            >
              {tabs.map((tab, key) => <BottomNavigationAction key={key} label={tab.label} icon={tab.icon} />)}
            </BottomNavigation>
          </Box>
        </Box>
      }
      {signed.value === true && sessionData.value.userCreated === false &&
        // Create new user screen
        <SignUp
          profileImg={authHolder.value?.user.photoURL ?? undefined}
          defaultName={authHolder.value?.user.displayName ?? undefined}
          //TODO uid cannot be undefined, protect
          authHolder={authHolder.value!}
          refreshSession={refreshSession}
        />
      }
    </Box >
  );
}
