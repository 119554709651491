import './App.css';
import { Routes, Route, HashRouter, BrowserRouter } from "react-router-dom";
import NotFound from './pages/NotFound/NotFound';
import Fatal from './pages/Fatal/Fatal';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import Home from './pages/Home/Home';
import ReceiptPage from './pages/Receipt/Receipt';
import { LandingPage } from './pages/LandingPage/LandingPage';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/300.css';

import "./fonts/300n.woff2"
import "./fonts/500n.woff2"
import "./fonts/700n.woff2"
import "./fonts/900n.woff2"
import "./fonts/300i.woff2"
import "./fonts/500i.woff2"
import "./fonts/700i.woff2"
import "./fonts/900i.woff2"

const white = "#FFF"
const mainBlue = "#0B3558"
const secondaryBlue = "#006BFF"

declare module '@mui/material/styles' {
  interface Mixins {
    ch: (h: number) => string;
    cw: (w: number) => string;
    cm: (w: number, h: number) => string;
  }
}


// https://bareynol.github.io/mui-theme-creator/#Typography
var theme = createTheme({
  mixins: {
    ch: (h) => `calc(${h} * max(1vh, 6.4px))`,
    cw: (w) => `calc(${w} * max(1vw, 4.8px))`,
    cm: (w, h) => `min(calc(${h} * max(1vh, 6.4px)),calc(${w} * max(1vw, 4.8px)))`,
  },
  components: {
    MuiBottomNavigation: {
      defaultProps: {
        sx: {
          //TODO fetch color from theme
          backgroundColor: '#0f1048',
        },
      },
    },
    MuiBottomNavigationAction: {
      defaultProps: {
        sx: {
          color: 'white',
        }
      }
    }
  },
  typography: {
    fontFamily: `"Woff2", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 500,
    fontWeightMedium: 700,
    fontWeightBold: 900,
  },
  palette: {
    background: {
      default: '#FFF',
      paper: '#FFF',
    },
    primary: {
      light: 'none',
      main: '#006BFF',
      dark: '#0B3558',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#0f1048',
      contrastText: '#FFF',
    },
    text: {
      primary: '#0B3558',
      secondary: '#006BFF',
    }
  },
  transitions: {
    duration: {
      enteringScreen: 200,
      leavingScreen: 300,
    }
  }
});
// theme = responsiveFontSizes(theme);

function App() {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path={'/r/:paymentID'} element={<ReceiptPage />} />
            <Route path={'/'} element={<LandingPage />} />
            <Route path={'/painel/*'} element={<Home />} />
            <Route path={'/health'} element={<h1>Healthy</h1>} />
            <Route path="/404" element={<NotFound />} />
            <Route path="/fatal" element={<Fatal />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
