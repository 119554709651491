import { Email, WhatsApp } from "@mui/icons-material";
import { Avatar, Box, IconButton, Stack, Typography } from "@mui/material";
import { openInNewTab } from "../../utils/NewTab";
import { Buffer } from "buffer";
import CompanyAvatar from "../CompanyAvatar/CompanyAvatar";

export function GetHeader() {
  return Buffer.from('bWFpbHRvCg==', 'base64').toString(); // mailto
}
export function GetUser() {
  return Buffer.from('c3Vwb3J0ZQ==', 'base64').toString(); // suporte
}
export function GetDomain() {
  return Buffer.from('ZGlhMS5pbw==', 'base64').toString(); // dia1.io
}
export function GetSupport() {
  return Buffer.from('aHR0cHM6Ly93YS5tZS81NTExOTkxMzI0MjQ2', 'base64').toString(); // https://wa.me/5511991324246
}

export function Help() {

  return <Box height='100%' width='100%' display='flex' alignContent='center' justifyContent='center'>
    <Stack height='100%' direction='column' margin={6} display='flex' alignItems='center'>
      <CompanyAvatar size={100} />
      <Typography variant='h4' align='center'>Como podemos ajudar?</Typography>
      <Typography variant='h6' align='left' paddingTop={3}>A Dia1 quer saber as sugestões, dúvidas e problemas.</Typography>
      <Typography variant='h6' align='left' paddingBottom={3}>Ficaremos muito felizes com o seu contato!</Typography>
      <IconButton onClick={() => openInNewTab(GetSupport())}><WhatsApp sx={{ margin: 1 }} /> Whatsapp</IconButton>
      <IconButton onClick={() => openInNewTab(`${GetHeader()}:${GetUser()}\x40${GetDomain()}`)}><Email sx={{ margin: 1 }} /> Email</IconButton>
    </Stack >
  </Box >
}